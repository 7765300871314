import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import { Avatar, Container, Grid, Typography } from '@mui/material/';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ theme: { palette } }) => ({
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    '& > ul': {
        overflow: 'hidden',
        zIndex: -100,
        pointerEvents: 'none',
    },
    '& > ul > li': {
        position: 'absolute',
        display: 'block',
        listStyle: 'none',
        opacity: 0,
        width: 400,
        height: 400,
        borderRadius: '50%',
        background: palette.primary.light,
        animation: 'animate 40s linear infinite',
    },
    '& > ul > li:nth-child(1)': {
        left: '40%',
        animationDelay: '0s',
        animationDuration: '50s',
    },
    '& > ul > li:nth-child(2)': {
        left: '60%',
        animationDelay: '4s',
        animationDuration: '55s',
    },
    '& > ul > li:nth-child(3)': {
        left: '20%',
        animationDelay: '6s',
        animationDuration: '60s',
    },
    '@keyframes animate': {
        '0%': {
            transform: 'translateX(0px) translateY(-500px)',
            opacity: '0',
        },
        '50%': {
            transform: 'translateX(-1000px) translateY(600px)',
            opacity: '0.25',
        },
        '100%': {
            transform: 'translateX(1000px) translateY(-300px)',
            opacity: '0',
        },
    },
}));

export default function AboutUs() {
    return (
        <Layout>
            <Container maxWidth="md" sx={{ mt: 4, p: 4 }}>
                <Typography variant="h4" color="primary" mb={4} gutterBottom>
                    About Dezkr
                </Typography>
                <Typography variant="subtitle1" my={4}>
                    Dezkr wants to be every product manager’s digital desk.
                    Feedback is the cornerstone for the success of any product.
                    <br />
                    Easy to get but hard to organize, act and action upon. Our
                    Feedback Dezk solves that problem for you.
                    <br />
                    So what are you waiting for? Set up your feedback dezk in 60
                    seconds!
                </Typography>
                <Typography variant="h4" color="primary" gutterBottom mb={4}>
                    Our Team
                </Typography>

                <Grid container spacing={4} rowGap={3}>
                    <Grid item xs={4} sx={{ mt: 2 }}>
                        <Avatar alt="Sumedha" sx={{ height: 250, width: 250 }}>
                            <StaticImage
                                src="../images/sumedha.jpeg"
                                alt="Dezkr - Product Feedback Simplified"
                                loading="eager"
                                placeholder="blurred"
                                transformOptions={{ fit: 'contain' }}
                            />
                        </Avatar>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom mb={2}>
                            Sumedha Narayanan, Co-founder And CTO
                        </Typography>
                        <Typography variant="subtitle1">
                            Full stack developer, supermom, puzzle lover and
                            foodie, Sumedha is an HR professional turned
                            computer programmer. She holds an MBA from XLRI
                            Jamshedpur and Bachelors in Mechanical Engg. from
                            MIT, Manipal.
                            <br /> After working with Unilever and Amazon for
                            more than 5 years in the HR space she decided to
                            start her second career in technology.
                            <br />
                            Before founding Dezkr, she worked as a full stack
                            developer for Wizeprep, a Vancouver based edtech
                            startup for 3 years.
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ mt: 2 }}>
                        <Avatar alt="Saurabh" sx={{ height: 250, width: 250 }}>
                            <StaticImage
                                src="../images/saurabh.jpeg"
                                alt="Dezkr - Product Feedback Simplified"
                                loading="eager"
                                placeholder="blurred"
                                transformOptions={{ fit: 'contain' }}
                            />
                        </Avatar>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom mb={2}>
                            Saurabh Dobhal, Co-founder And CEO
                        </Typography>
                        <Typography variant="subtitle1">
                            Product leader, runner and yoga enthusiast, Saurabh
                            started working in product management more than a
                            decade ago. During this time he had the opportunity
                            to work on complex B2B products as well as B2C
                            solutions.In his last role he worked as the Chief
                            Product Officer at Wizeprep, a Vancouver based
                            edtech startup. He holds a bachelors in Mechanical
                            Engg. from MIT, Manipal and a dual MS(Information
                            Systems) and MBA from Boston University.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <StyledDiv>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </StyledDiv>
        </Layout>
    );
}

export const Head = () => {
    return (
        <Seo
            title="About Dezkr's Founders"
            description="Founded by Saurabh Dobhal and Sumedha Narayanan, Dezkr wants to be every product manager’s digital desk."
        />
    );
};
